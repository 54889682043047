<script>
  import { onMount } from "svelte";
  import _ from "lodash";

  import Card from "./Card.svelte";

  const resize = _.debounce(e => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, 300);
</script>

<svelte:window on:resize={resize} />

<Card />
