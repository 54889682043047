<script>
  import { tick, onMount } from "svelte";
  import { tweened } from "svelte/motion";
  import { cubicInOut } from "svelte/easing";
  import { crossfade, scale } from "svelte/transition";
  import _ from "lodash";
  import Works from "./Works.svelte";

  let name = "未来美学网路设计工作室";
  let src = "./me.jpeg";

  const FULLPAGE = true;
  const WECHAT_QRCODE = "./wechat_qrcode.jpg";
  const [send, receive] = crossfade({
    duration: 200,
    fallback: scale
  });

  let selected = null;
  let loading = null;

  const showWechatQrcode = image => {
    const timeout = setTimeout(() => (loading = image), 100);

    const img = new Image();

    img.onload = () => {
      selected = image;
      clearTimeout(timeout);
      loading = null;
    };

    img.src = `${image}`;
  };

  let scrolled = true;
  let isScrolling = false;
  let showWorks = !FULLPAGE;

  const wheel = _.throttle(e => {
    if (e.deltaY > 0) {
      showWorks = true;
    } else {
      showWorks = false;
    }
  }, 16);

  function toggleCard(e) {
    showWorks = !showWorks;
  }

  onMount(() => {
    const timer = setTimeout(toggleCard, 2000);
    return () => clearTimeout(timer);
  });
</script>

<style>
  :global(body) {
    padding: 0;
    margin: 0;
    width: 100vw;
    min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    color: #232323;
  }

  .bgc {
    cursor: pointer;
  }

	.bgc:focus, .bgc:active {
    background-color: whitesmoke;
	}

  .avatar,
  .social {
    transition: 0.4s all cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .page {
    background-color: whitesmoke;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .nav {
    position: sticky;
    top: 0;
    width: 100vw;
    background-color: #fff;
    box-shadow: 0 0 1px 0 rgba(128, 128, 128, 0.1),
      0 2px 6px 0 rgba(128, 128, 128, 0.16);
  }

  .nav .card {
    display: flex;
    justify-content: space-between;
    padding: 0.5em 1em;
  }

  .nav .bgc {
    flex: initial;
  }

  .nav .link {
    flex: initial;
    text-align: right;
  }

  .nav h4 {
    display: none;
  }

  .nav .avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #fff;
    padding: 4px;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
  }

  .nav .link a {
    display: inline-block;
    margin: 0 0.8em 0 0;
    padding: 0;
  }

  .nav .link a:last-child {
    margin: 0;
  }

  .nav .social {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin: 0;
    border-radius: 50%;
    background-color: #fff;
    padding: 4px;
    box-shadow: 0 0 1px 1px rgba(128, 128, 128, 0.1);
  }

  .page .card {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .page .bgc {
    margin: 0 auto;
    padding: 2em;
    border-radius: 1em;
    background-color: #fff;
  }

  .page .avatar {
    display: block;
    width: 200px;
    height: 200px;
    margin: 0 auto;
  }
  .page h4 {
    text-align: center;
  }
  .page .link {
    width: auto;
    text-align: center;
    margin: 2em auto 0;
  }

  .page .link a {
    display: inline-block;
    margin: 0 0.8em 0 0;
    padding: 0;
  }

  .page .link a:last-child {
    margin: 0;
  }

  .page .social {
    display: inline-block;
    width: 32px !important;
    height: 32px;
    background-color: #fff;
    border-radius: 50%;
    padding: 0.5em;
    margin: 0;
  }

  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 990;
  }

  .modal .backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.85);
  }

  .modal .card {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .modal .qrcode {
    display: block;
    max-width: 60vw;
    max-height: 50vh;
    border-radius: 1em;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  }
</style>

<svelte:body on:wheel={wheel} on:touchmove={wheel} />

<!-- content here -->
<div class={!showWorks ? 'page' : 'nav'}>
  <div class="card">
    <div class="bgc" on:click={toggleCard}>
      <img class="avatar" {src} alt="我真好看" />
      <h4>{name}</h4>
    </div>

    <div class="link">
      <a href="https://weibo.com/clovelee" class="other">
        <img class="social weibo" src="./weibo3.svg" alt="" />
      </a>
      <a
        href="javascript:;"
        on:click={() => showWechatQrcode(WECHAT_QRCODE)}
        in:receive={{ key: WECHAT_QRCODE }}
        out:send={{ key: WECHAT_QRCODE }}
        class="other">
        <img class="social weixin" src="./weixin.svg" alt="" />
      </a>
    </div>
  </div>
</div>

{#if showWorks}
  <Works />
{/if}

{#if selected}
  {#await selected then d}
    <div
      class="modal"
      in:receive={{ key: d }}
      out:send={{ key: d }}
      on:click={() => (selected = null)}>
      <div class="backdrop" />
      <div class="card">
        <img class="qrcode" alt="wechat qrcode" src={WECHAT_QRCODE} />
      </div>
    </div>
  {/await}
{/if}
