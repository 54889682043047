<script>
  let src = "./catface.png";
  let works = [
    {
      id: "https://huatang.netlify.com/",
      name: "花糖",
			description: "无门槛从事美妆代理，正品靠谱货源，赚点小钱够了。",
			remote: "长期招募美妆代理，无押金，零成本，想要从事的+V。",
      src: "./ht.png"
    },
    {
      id: "https://nian.me",
      name: "猫脸书签",
      description: "最全最高质量的行业网址导航",
			remote: "需要一名开发人员做几个小功能",
      src: "./catface.svg"
    },
    {
      id: "https://clovelee.github.io/simplelife/",
      name: "简单生活的101个法则",
      description: "懒人生活宝典",
			remote: "需要编辑一名辅助编写",
      src: "./me.jpeg"
		},
		{
      id: "https://clovelee.github.io/dics/",
      name: "工作常用中英文对照",
      description: "开发过程常用英语词汇整理",
  		remote: "有其他行业需求的，请联系我添加。",
    	src: "./dics.svg"
    }
  ];
</script>

<style>
  :global(body) {
    padding: 0;
    margin: 0;
    color: #232323;
    background-color: #f2f3f2;
  }
  .works {
    padding: 2em;
  }
  .works h5 {
    font-size: 18px;
    margin-block-start: 0;
  }
  .works ul {
    margin-block-start: 0em;
    margin: 0;
    padding: 0;
  }
  img {
    width: 40px;
    height: 40px;
    padding: 0.5em;
    border-radius: 16px;
    background-color: #fff;
  }
  ul li {
    list-style: none;
    margin-bottom: 2em;
  }
  .title {
    margin-left: 10px;
    font-weight: 500;
    margin-bottom: 4px;
  }
  .description {
    margin-left: 10px;
    font-size: 14px;
    color: gray;
  }
	.remote {
		display: block;
		margin-left: 10px;
    font-size: 14px;
    color: black;
	}
  .works a {
    color: #232323;
    display: flex;
    align-items: center;
  }
  .works a:hover {
    text-decoration: none;
    color: blueviolet;
  }
</style>

<div class="works">
  <h5>项目列表</h5>
  <ul>
    {#each works as { id, name, description, remote, src }, i}
      <li>
        <a target="_blank" href={id}>
          <img {src} alt="" />
          <div>
            <span class="title">{name}</span>
            <span class="description">{description}</span>
            <span class="remote">{remote}</span>
          </div>
        </a>
      </li>
    {/each}
  </ul>
</div>
